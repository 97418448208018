


























import { Vue, Component } from "vue-property-decorator";
import CrewMembersInCountryChart from "@/components/charts/CrewMembersInCountryChart.vue";
import SalaryPerJobRoleChart from "@/components/charts/SalaryPerJobRoleChart.vue";
import SalaryPerNationalityChart from "@/components/charts/SalaryPerNationalityChart.vue";
import InCountryPerNationalityChart from "@/components/charts/InCountryPerNationalityChart.vue";
import OutCountryPerNationalityChart from "@/components/charts/OutCountryPerNationalityChart.vue";

@Component({
  components: {
    CrewMembersInCountryChart,
    SalaryPerJobRoleChart,
    SalaryPerNationalityChart,
    InCountryPerNationalityChart,
    OutCountryPerNationalityChart
  }
})
export default class Analyitics extends Vue {}
